import './App.css';
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './components/Header';
import NavigationBar from './components/NavigationBar';
import DisplayContent from './components/DisplayContent';
import Footer from './components/Footer';


export default function App() {
    const [contentKey, setContentKey] = useState('home');
    return (
        <Container className='col-12 col-lg-10'>
            <Header />
            <NavigationBar setContentKey={setContentKey} />
            <DisplayContent contentKey={contentKey} />
            <Footer/>
        </Container>
    );
}

