import { Container, Row, Col, Image } from "react-bootstrap";
export default function DSP(){
    //Funcitons or variable
    return (
        <Container>
            <Row className="mb-3">
                <Col className="col-lg-6 col-12 m-auto roduct-description">
                    <h3 className="mb-3">H8车载DSD-DSP一体播放器</h3>
                    <p> H8 是一款高端DSD数播与DSP合为一体的车载数播产品，操作简单、工作稳定、性价比高。适用于市面上98%以上车型，可依据不同车型空间大小调节到接近影院的音效。让您在旅途中纵情享受到如临影院的纯真音乐。是市场为数不多支持DSD1024的DSD-DSP一体播放器之一！</p>
                    <p> 本产品采用了目前最新最前沿的科技手段来解析音频，最大限度地保障了音乐完整性。不论从产品的操控方式、兼容性、性价比，H8的技术凝聚了几十位工程师的竭尽智慧。深受国内外发烧友的喜爱！</p>
                </Col>
                <Col className="col-lg-6 col-12 m-auto">
                    <Image src="/images/MBYS/H8.png" fluid className="product-img" ></Image>
                </Col>
            </Row>
            <Row className="flex-column-reverse flex-lg-row mb-3">
                <Col className="col-lg-6 col-12 m-auto">
                    <Image src="/images/MBYS/H12.png" fluid className="product-img" ></Image>
                </Col>
                <Col className="col-lg-6 col-12 m-auto roduct-description">
                    <h3 className="mb-3">H12国产车载带FIR滤波器DSD-DSP领跑者！</h3>
                    <p>H12是高端DSD数播与DSP功放合为一体的车载数播产品，操作简单、工作稳定、性价比高。DSD高解析音源+DSP声场处理+HIFI后级功放，一机可将您的爱车打造成专属的私人空间。此产品是国内第一台带FIR滤波器功能的DSD，特别是基于Linux系统的DSD高解析音源系统是当前音响行业最前沿的科技技术，可使音乐每一个细节、每一个音符接近原声地解析出来，似乎把舞台搬到您眼前，如亲临演唱现场，让你真实感受到音乐的魅力无限。此产品必将成为你享受生活的法宝。</p>
                </Col>
            </Row>
        </Container>
    );
}
