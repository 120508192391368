import { FiFacebook } from "react-icons/fi";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { Col, Container, Row } from "react-bootstrap";

export default function Header() {

    return (
        <Container>
            <Row className="header text-center">
                <Col xs className="d-flex align-items-center justify-content-center col-2">
                    <a href="https://www.facebook.com/people/%E9%A0%86%E8%88%88%E6%B1%BD%E8%BB%8A%E9%9F%B3%E9%9F%BF/100063849429018/?locale=zh_HK">
                        <FiFacebook className="social-icon" />
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=85252233438">
                        <AiOutlineWhatsApp className="social-icon" />
                    </a>
                </Col >
                <Col xs={6} className="d-flex align-items-center justify-content-center ">
                    <a className="co-banner">Shun Hing Stereo</a>
                </Col>
                <Col xs={4} className="d-flex align-items-center justify-content-center">
                    <a href="/" className="logreg">順興汽車音響 </a>
                </Col>
            </Row>
        </Container>
    );
}
