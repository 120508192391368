import { Col, Container, Row, Table } from "react-bootstrap";
import { BsGeoAlt } from "react-icons/bs";
export default function ContactUs() {

    return (
        <div fluid='lg'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#f9f9f9" fillOpacity="1"
                    d="M0,96L40,117.3C80,139,160,181,240,186.7C320,192,400,160,480,149.3C560,139,640,149,720,176C800,203,880,245,960,250.7C1040,256,1120,224,1200,229.3C1280,235,1360,277,1400,298.7L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z">
                </path>
            </svg>
            <Row className="justify-content-start">
                <Col className="col-12 col-md-6">
                    <h5 className="mb-2">辦公室地址</h5>
                    <p className="mb-2">
                        <BsGeoAlt />
                        香港新界上水巡撫街22號D地下
                    </p>
                    <br />
                    <Table>
                        <thead>
                            <tr>
                                <th>營業時間</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>星期一:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期二:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期三:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期四:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期五:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期六:</td>
                                <td>12:00 pm - 08:00 pm</td>
                            </tr>
                            <tr>
                                <td>星期日:</td>
                                <td>休息</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col className="col-12 col-md-6">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14743.844477871582!2d114.1282179!3d22.5056419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f60e261b0701%3A0x5345f841a18c5302!2z6aCG6IiI6Z-z6Z-_!5e0!3m2!1sen!2shk!4v1699810598211!5m2!1sen!2shk"
                        width="100%"
                        height="420"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </Col>
            </Row>



            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#36363e" fillOpacity="1"
                    d="M0,96L40,117.3C80,139,160,181,240,186.7C320,192,400,160,480,149.3C560,139,640,149,720,176C800,203,880,245,960,250.7C1040,256,1120,224,1200,229.3C1280,235,1360,277,1400,298.7L1440,320L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z">
                </path>
            </svg>

        </div>
    );
}
