import { Col,Image } from "react-bootstrap";

export default function GoldenAcustics(){
    //Funcitons or variable
    return (
        <Col className="text-center" style={{ maxWidth: "600px", margin: "0 auto"}}>
            <Image src="/images/GA/banner.jpeg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/VE1150.jpeg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/ME275.jpeg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/ME90C.jpg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/MA-W104.jpeg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/MC3.jpeg" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
            <Image src="/images/GA/B4.png" className="d-block mx-auto img-fluid mb-3" style={{ maxWidth: "100%", height: "auto" }}></Image>
        </Col>
    );
}
