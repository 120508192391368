import { Badge, Carousel, Col, Image } from "react-bootstrap";

export default function Home() {
    //Funcitons or variable
    return (
        <div className="justify-content-md-center">
            <div>
                <p>順興汽車音響 Shun Hing Stereo 成立於1996年，提供汽車音響防盜升級服務。專營品牌包括：Golden Acoutics（黃金聲學）、美國Recoil、木爸原聲DSP、梁山導航</p>
                <p>順興一貫的經營理念為「價格公開透明、誠信服務品質」，二十幾年來獲得廣大客戶贊許與好評。</p>

                <h1><Badge bg="primary">💥最新消息💥</Badge></h1>
                <em>本店新增設Visa，Master信用卡簽卡＆AE信用卡簽卡或分期，還🉑PayMe或轉數快🤗。。。仲有仲有易辦事同銀聯。。。歡迎使用支付寶或微信支付🤩加埋八達通。想點俾都得。歡迎使用消費券🙌  </em>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <Carousel>
                    <Carousel.Item>
                        <Image src="/images/HC/GoldenAcustic.jpeg" style={{ maxWidth: '100%', height: 'auto' }}></Image>
                        <Carousel.Caption>
                            <h1 className="text-white mt-2 mb-4">Golden Acoustics</h1>
                            <h3 className="text-white mt-2 mb-4">Build The Best Audio System for Car</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="/images/HC/Recoil.jpeg" style={{ maxWidth: '100%', height: 'auto' }}></Image>
                        <Carousel.Caption>
                            <h1 className="text-white mt-2 mb-4">美國RECOIL</h1>
                            <h3 className="text-white mt-2 mb-4">The badass ECHO Series coaxial & component speakers</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="/images/HC/DSP.png" style={{ maxWidth: '100%', height: 'auto' }}></Image>
                        <Carousel.Caption>
                            <h1 className="text-white mt-2 mb-4">木爸原聲</h1>
                            <h3 className="text-white mt-2 mb-4">使音乐每一个细节、每一个音符接近原声地解析出来，让你真实感受到音乐的魅力无限。</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image src="/images/HC/LSDH.jpeg" style={{ maxWidth: '100%', height: 'auto' }}></Image>
                        <Carousel.Caption>
                            <h1 className="text-white mt-2 mb-4">梁山導航</h1>
                            <h3 className="text-white mt-2 mb-4">只为做一款真正属于发烧友车机导航，这一切不为输赢，我们是认真的。</h3>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    );
}
