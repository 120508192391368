import { Col, Image } from "react-bootstrap";

export default function LSDH() {
    //Funcitons or variable
    return (
        <Col>
            <Image
                src="/images/LSDH/lsdh-demo.jpeg"
                className="d-block mx-auto img-fluid"
            ></Image>
        </Col>
    );
}
