import Home from "../Pages/Home";
import GoldenAcustics from "../Pages/GoldenAcustics";
import Recoil from "../Pages/Recoil";
import DSP from "../Pages/DSP";
import LSDH from "../Pages/LSDH";
import ContactUs from "../Pages/ContactUs";

export default function DisplayContent({ contentKey }) {
    let showContent = null;
    switch (contentKey) {
        case 'home':
            showContent = <Home />
            break;
        case 'ga':
            showContent = <GoldenAcustics />
            break;
        case 'recoil':
            showContent = <Recoil />
            break;
        case 'dsp':
            showContent = <DSP />
            break;
        case 'lsdh':
            showContent = <LSDH />
            break;
        case 'contactUs':
            showContent = <ContactUs />
            break;
        default:
            showContent = null
            break;
    }


    return showContent;
}

