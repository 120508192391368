import { Container, Row, Col, Image } from "react-bootstrap";

export default function Recoil() {
    //Funcitons or variable
    return (
        <Container>
            <Row className="mb-3">
                <Col className="col-lg-6 col-12 m-auto roduct-description">
                    <h3 className="mb-3">REM525 Echo Series 5.25″ Component Speaker System</h3>
                    <p>輕質熱成型聚丙烯音盆可實現更快的中頻響應。環繞/錐體耦合採用高溫黏合劑，確保錐體和環繞體在高功率使用中不會分離。丁腈橡膠環繞提供耐用性和更深的中低音衝擊力。</p>
                    <p>1 吋絲質軟球頂高音揚聲器採用超大音圈，可實現更好的散熱和更高的功率處理能力，從而帶來平滑、無疲勞的高音。鐵氧體磁鐵馬達可產生強大的磁場，同時保持足夠小的尺寸以適合幾乎所有應用。適合大多數汽車，具有多功能性和便利性。無論您是齊平安裝還是表面安裝高音揚聲器，安裝座都具有無與倫比的靈活性。三級高音揚聲器音量調整有助於彌補不太完美的揚聲器位置，甚至可以根據您的個人聆聽偏好和音樂品味進行設定。</p>
                </Col>
                <Col className="col-lg-6 col-12 m-auto">
                    <Image src="/images/Recoil/REM525-5.jpg" fluid className="product-img" ></Image>
                </Col>
            </Row>
            <Row className="flex-column-reverse flex-lg-row mb-3">
                <Col className="col-lg-6 col-12 m-auto">
                    <Image src="/images/Recoil/REM65.jpg" fluid className="product-img" ></Image>
                </Col>
                <Col className="col-lg-6 col-12 m-auto roduct-description">
                    <h3 className="mb-3">REM65 Echo Series 6.5-Inch Car Audio Component Speaker System</h3>
                    <p>輕質熱成型聚丙烯音盆可實現更快的中頻響應。環繞/錐體耦合採用高溫黏合劑，確保錐體和環繞體在高功率使用中不會分離。丁基橡膠環繞提供耐用性和更深的中低音衝擊力。絲質軟球頂高音揚聲器具有超大音圈，可實現更好的散熱和更高的功率處理能力，從而產生平滑、無疲勞的高音。</p>
                    <p>無論您是齊平安裝還是表面安裝高音揚聲器，安裝座都具有無與倫比的靈活性。三級高音揚聲器音量調整有助於補償不太完美的揚聲器位置，甚至可以進行設定以滿足您的個人聆聽偏好和音樂品味。鐵氧體磁鐵馬達可產生強大的磁場，同時保持足夠小的尺寸以適合幾乎所有應用。</p>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="col-lg-6 col-12 m-auto roduct-description">
                    <h3 className="mb-3">RET1 Echo Series 1-Inch Car Audio Component Neodymium Silk Dome Tweeters with triple-level tweeter crossover, Pair</h3>
                    <p>高效能 Echo 系列組件高音揚聲器系統 – 2個高音揚聲器</p>
                    <ul>
                        <li>最大功率：100 瓦（一對）</li>
                        <li>RMS 功率：50 瓦（一對）</li>
                        <li>強力釹磁鐵</li>
                        <li>靈敏度：92 分貝。優質絲質圓頂設計可實現極致的聲音清晰度</li>
                        <li>頻率響應：2.0KHz – 20KHz</li>
                        <li>三級高音揚聲器分頻調整有助於補償不太完美的揚聲器放置，甚至可以進行設定以滿足您的個人聆聽偏好和音樂品味。</li>
                        <li>表面、角度和齊平安裝設計</li>
                        <li>包含安裝配件和硬件</li>
                    </ul>

                </Col>
                <Col className="col-lg-6 col-12 m-auto">
                    <Image src="/images/Recoil/RET1.jpg" fluid className="product-img" ></Image>
                </Col>
            </Row>
        </Container>
    );
}
