import { Row, Col, Container } from "react-bootstrap";
import { FiFacebook } from 'react-icons/fi';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { BsGeoAlt, BsTelephone } from 'react-icons/bs';
import { TfiEmail } from 'react-icons/tfi';



export default function Footer() {

    return (
        <Container className="mt-1">
            <Row className="bg-dark text-white">
                <h5>
                    順興汽車音響 {' '}
                    <a href="https://www.facebook.com/people/%E9%A0%86%E8%88%88%E6%B1%BD%E8%BB%8A%E9%9F%B3%E9%9F%BF/100063849429018/?locale=zh_HK">
                        <FiFacebook className="social-icon" />
                    </a>
                    {' '}
                    <a href="https://api.whatsapp.com/send?phone=85252233438">
                        <AiOutlineWhatsApp className="social-icon" />
                    </a>
                </h5>
            </Row>
            <Row className="bg-dark text-white justify-content-start">
                <Col className="col-12 col-md-5">
                    <p><BsGeoAlt />香港新界上水巡撫街22號D地下</p>
                </Col>
                <Col className="col-12 col-md-3">
                    <p><BsTelephone />{' '} +852 5223 3438 </p>
                </Col>
                <Col className="col-12 col-md-4">
                    <p><TfiEmail />{' '} action.co@hotmail.com  </p>
                </Col>

            </Row>
            <Row className="just">
                <p className="copyright-text mb-0 me-4">Copyright © 2023 Shun Hing Stereo</p>
            </Row>
        </Container>
    );
}
