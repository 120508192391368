import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';

export default function NavigationBar({setContentKey}) {
    //Funcitons or variable
    return (
            <Navbar sticky="top" collapseOnSelect className="bg-light mb-3 fixed-top shadow-lg" expand='md'  >
                <Navbar.Brand href='/' style={{height:'50px'}}>
                    <Image 
                    src='/images/ShunHing-logo-1.png' 
                    style={{ height: '100%', width: 'auto'}}
                    ></Image>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
                
                <Navbar.Offcanvas aria-labelledby='offcanvasNavbarLabel-expand-sm' placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            Shun Hing Stereo
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav
                            className="justify-content-end flex-grow-1 pe-3"
                            onSelect={selectedkey => setContentKey(selectedkey)}
                        >
                            {/* <Nav.Link eventKey='aboutUs'></Nav.Link> */}
                            <Nav.Link eventKey='ga'>Golden Acoustics</Nav.Link>
                            <Nav.Link eventKey='recoil'>Recoil</Nav.Link>
                            <Nav.Link eventKey='dsp'>木爸原聲</Nav.Link>
                            <Nav.Link eventKey='lsdh'>梁山導航</Nav.Link>
                            <Nav.Link eventKey='contactUs'>Contact us</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>

                </Navbar.Offcanvas>
            </Navbar>
    );
}
